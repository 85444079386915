/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/** @jsx jsx */
import {useState} from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {graphql, Link} from 'gatsby'
import {jsx, Styled} from 'theme-ui'
import {Layout} from '../components'

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(
      'pk_test_51HOglLJcWTRDu3tEKICp4FJuimNCL9KZ9kFdcCkoBHtjyabrqdJxId9fCZJ1Gbat5OLej0AbzsrDB4araqdnNmfx00BxU4eoOr'
    )
  }
  return stripePromise
}

const Abgesagt = ({data: {BookPrice, Buch}}) => {
  const [loading, setLoading] = useState(false)

  const redirectToCheckout = async (event) => {
    event.preventDefault()
    setLoading(true)
    const stripe = await getStripe()
    const {error} = await stripe.redirectToCheckout({
      lineItems: [{price: BookPrice.id, quantity: 1}],
      mode: 'payment',
      successUrl: `https://daliapugatsch.netlify.app/erfolgreich`,
      cancelUrl: `https://daliapugatsch.netlify.app/abgesagt`,
      shippingAddressCollection: {
        allowedCountries: ['CH'],
      },
    })
    if (error) {
      console.warn('Error:', error)
      setLoading(false)
    }
  }

  return (
    <Layout customNav>
      <div
        sx={{
          minHeight: '30vh',
          textAlign: 'center',
          p: '0 1.5rem',
          maxWidth: '900px',
          m: [
            'calc(64px + 2rem) auto 6rem auto',
            '',
            'calc(64px + 13rem) auto calc(64px + 13rem) auto',
          ],
        }}
      >
        <Styled.h1
          sx={{
            color: (t) => t.colors.red[6],
          }}
        >
          {Buch.data.fail_title}
        </Styled.h1>
        <div
          sx={{
            p: {
              fontSize: '1.5rem',
            },
          }}
          dangerouslySetInnerHTML={{__html: Buch.data.fail_content.html}}
        />

        <div
          sx={{
            mt: '2rem',
          }}
        >
          <button
            disabled={loading}
            onClick={redirectToCheckout}
            type="button"
            sx={{
              mr: '2rem',
              mb: ['1.5rem', '', '0'],
              variant: 'buttons.primary',
            }}
          >
            Versuch es noch einmal
          </button>
          <Link to="/kunst-revolution">
            <button
              type="button"
              sx={{
                variant: 'buttons.secondary',
              }}
            >
              Zur Seite des Kunstbuches
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Failed {
    BookPrice: stripePrice(product: {id: {eq: "prod_HyySVpqkIuSKER"}}) {
      id
      unit_amount
      currency
    }
    Buch: prismicBuch {
      data {
        fail_title
        fail_content {
          html
        }
        book_carousel {
          image {
            url
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Abgesagt
